import React, { useContext, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { AccessDeniedContent } from './styles'
import { AppBar, Icon, textSecondary } from 'everchain-uilibrary'
import {
  APP_NAME_BUYER,
  APP_NAME_NON_BUYER,
  BUYER,
  DISPLAY_NAME,
  IS_IMPERSONATING,
} from 'src/utils/constants'
import { AuthContext, useAuth } from 'src/context/AuthenticationContext'

const AccessDenied = () => {
  const authenticationObj = useAuth()
  const [logoutCooldown, setLogoutCooldown] = useState(false)
  const userId = window.localStorage.getItem('userId')
  const { userPermissions } = useContext(AuthContext)

  const isBuyer = userPermissions.type.toLowerCase() === BUYER.toLowerCase()

  const userAppName =
    authenticationObj?.user?.profile[IS_IMPERSONATING] === true
      ? `Impersonating ${authenticationObj?.user?.profile[DISPLAY_NAME]}`
      : authenticationObj?.user?.profile[DISPLAY_NAME]

  const changePasswordlink = `${process.env.REACT_APP_AUTHORITY_URL}/account/ChangePassword?userid=${userId}&returnurl=${origin}`

  const handleOnLogout = (): void => {
    if (!logoutCooldown) {
      setLogoutCooldown(true)
      authenticationObj.logout()
      setTimeout(() => {
        setLogoutCooldown(false)
      }, 6000)
    }
  }

  const handleChangepassword = (): void => {
    window.open(changePasswordlink, '_blank')
  }

  return (
    <>
      <AppBar
        appName={isBuyer ? APP_NAME_BUYER : APP_NAME_NON_BUYER}
        logoutProps={{ onClickLogout: handleOnLogout }}
        userProps={{
          userName: userAppName,
          onClickChangePassword: handleChangepassword,
        }}
      />
      <AccessDeniedContent>
        <Box width={180} height={180} className="icon-fade" textAlign="center">
          <Icon
            name="Lock"
            style={{
              fontSize: 140,
            }}
          />
        </Box>
        <Typography variant="h2" className="subtitle">
          Access Denied
        </Typography>
        <Typography color={textSecondary.color}>
          <Box component="span" fontSize={20}>
            You don't have permissions to access this page
          </Box>
        </Typography>
      </AccessDeniedContent>
    </>
  )
}

export default AccessDenied
