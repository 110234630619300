import React, { useEffect, useState } from 'react'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getPortfolioSurveyAnswers } from 'src/data/features/get/portfolio/portfolio'
import {
  Box,
  Button,
  EverChainLogoHorizontal,
  fConvertSvgToPng,
} from 'everchain-uilibrary'
import { generatePortfolioDSS } from 'src/data/features/post/portfolio/portfolio'
import { GeneratePortfolioDSSRequest } from 'src/data/features/post/portfolio/types'
import { HorizontalAlign, SurveyPDF } from 'survey-pdf'

interface PortfolioSurveyViewerProps {
  surveyId: number
  surveySchema: string
  businessId: string | undefined
  portfolioId: string | undefined
}

const surveyPdfOptions = {
  haveCommercialLicense: true,
  margins: {
    left: 10,
    right: 10,
    top: 10,
    bot: 10,
  },
}

const PortfolioSurveyViewer: React.FC<PortfolioSurveyViewerProps> = ({
  surveyId,
  surveySchema,
  businessId,
  portfolioId,
}: PortfolioSurveyViewerProps) => {
  const [generateDSSRequest, setGenerateDSSRequest] =
    useState<GeneratePortfolioDSSRequest>()
  const [enableDSSRequest, setEnableDSSRequest] = useState<boolean>(false)

  const { data: surveyAnswerQuery, isFetching: loadingSurveyAnswer } =
    useCustomQuery(
      ['getPortfolioSurveyAnswers', portfolioId, surveyId, businessId],
      async () =>
        getPortfolioSurveyAnswers(portfolioId, Number(surveyId), businessId),
      {
        enabled: !!businessId,
        cacheTime: 0,
      }
    )

  const {
    data: portfolioDSSFileUri,
    isFetching: loadingGeneratePortfolioDSS,
    isFetched: fetchedFile,
  } = useCustomQuery(
    ['generatePortfolioDSS', generateDSSRequest, enableDSSRequest],
    async () => generatePortfolioDSS(generateDSSRequest),
    {
      enabled: !!(generateDSSRequest && enableDSSRequest),
      cacheTime: 0,
    }
  )

  useEffect(() => {
    if (fetchedFile && portfolioDSSFileUri !== '') {
      window.location.href = portfolioDSSFileUri
    }
    setEnableDSSRequest(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioDSSFileUri, fetchedFile])

  if (loadingSurveyAnswer) return <>Loading...</>
  const answers = surveyAnswerQuery || ''

  const surveyModel = new Model(surveySchema || '')
  surveyModel.data = answers
  surveyModel.mode = 'display'

  const generateDSSPdf = async () => {
    const surveyPdf = new SurveyPDF(surveyModel.toJSON(), surveyPdfOptions)
    surveyPdf.data = surveyModel.data

    const logo = <EverChainLogoHorizontal />
    const base64Logo = await fConvertSvgToPng(logo, 300, 50)

    surveyPdf.onRenderHeader.add(async (_, canvas) => {
      await canvas.drawImage({
        base64: base64Logo,
        width: (canvas.rect.yBot - canvas.rect.yTop) * 4,
        height: (canvas.rect.yBot - canvas.rect.yTop) * 2,
        horizontalAlign: HorizontalAlign.Left,
        margins: {
          top: (canvas.rect.yBot - canvas.rect.yTop) * 0.4,
          bot: (canvas.rect.yBot - canvas.rect.yTop) * 0.1,
          left: (canvas.rect.yBot - canvas.rect.yTop) * 1,
        },
      })
    })

    surveyPdf.raw('blob').then((blobFile: any) => {
      const file = new File([blobFile], 'portfolioDSS', { type: blobFile.type })
      setGenerateDSSRequest({
        portfolioId,
        businessId,
        surveyId: Number(surveyId),
        file: file,
      })
      setEnableDSSRequest(true)
    })
  }

  const handleGenerateDSS = () => {
    generateDSSPdf()
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '500px' }}>
      <div style={{ paddingBottom: '10px', alignSelf: 'end' }}>
        <Box>
          <Button
            isFetching={loadingGeneratePortfolioDSS}
            onClick={() => handleGenerateDSS()}
          >
            Download
          </Button>
        </Box>
      </div>
      <Survey model={surveyModel} />
    </div>
  )
}

export default PortfolioSurveyViewer
